import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGoogleMaps from '@fawmi/vue-google-maps'

import 'bootstrap/dist/css/bootstrap.min.css'

const app = createApp(App)
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDflFZ9FAhRLXn1qTOC5asYzsYnbbyXcv0',
        libraries: "places"
    },
    region: 'JP',
    language: 'ja'
})

app.use(router)
app.mount('#app')
