<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <div class="input-group mb-1">
          <input type="text" class="form-control" @keyup.enter="searchLocation" v-model="searchQuery" placeholder="Search for a location">
          <button class="btn btn-primary" @click="searchLocation">Search</button>
        </div>
      </div>

      <div class="map-container">
        <div class="d-flex" style="margin-bottom: 2px">
          <button class="btn btn-sm btn-secondary" style="margin-right: 2px" @click="showCurrentLocation">現在地指定</button>
          <button class="btn btn-sm btn-success" style="margin-right: 2px" @click="storeCoords">会社指定</button>
          <button class="btn btn-sm btn-info" @click="showDistanceDuration = !showDistanceDuration">距離計算</button>
        </div>
        <div class="distance">
          <p class="text-dark windowStyles" v-if="currentLocation">現住所: {{currentLocation}}</p>
          <p class="text-dark windowStyles" v-if="destinationWindowDetails.address">行き先: {{destinationWindowDetails.address}}</p>
          <div v-if="showDistanceDuration">
            <p class="text-dark windowStyles" v-if="distance">距離: {{distance}}</p>
            <p class="text-dark windowStyles" v-if="duration">間隔: {{duration}}</p>
          </div>
        </div>
        <GMapMap
          ref="map"
          v-if="center"
          :center="center"
          :zoom="15"
          map-type-id="terrain"
          class="map-section"
          :options="{
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: true,
            rotateControl: true,
            fullscreenControl: true
          }"
          @click="setMarker"
        >
          <GMapMarker
              :position="center"
              ref="sourceMarker"
          >
            <GMapInfoWindow
              v-if="this.currentLocation"
              :position="this.center"
              @closeclick="closeInfoWindow"
              :options="{
                pixelOffset: {
                  width: 10,
                  height: 0
                },
                maxWidth: 320,
                maxHeight: 320
              }"
            >
              <div>
                <p>現在位置: {{ currentLocation }}</p>
              </div>
            </GMapInfoWindow>
          </GMapMarker>
          <GMapMarker
            ref="destinationMarker"
            v-if="destination"
            :position="destination"
            :clickable="true"
            :draggable="true"
            @click="openInfoWindow"
            @dragend="handleDestinationMarkerDragEnd"
          >
            <GMapInfoWindow
              v-if="destinationWindowDetails.address"
              :position="destination"
              @closeclick="closeInfoWindow"
              :options="{
                pixelOffset: {
                  width: 10,
                  height: 0
                },
                maxWidth: 320,
                maxHeight: 320
              }"
            >
              <div>
                <p class="windowStyles">会社所在地: {{ destinationWindowDetails.address }}</p>
              </div>
            </GMapInfoWindow>
          </GMapMarker>
          <GMapPolyline v-if="destination" :path="[center, destination]" :options="{strokeColor: 'red', strokeOpacity: 0.5, strokeWeight: 2}" />
        </GMapMap>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
export default {
  data() {
    return {
      center: null,
      searchQuery: '',
      destination: null,
      destinationName: '',
      currentLocation: '',
      distance: null,
      duration: null,
      showDistanceDuration: false,
      destinationWindowDetails: {
        address: ''
      },
      sourceWindowDetails: {
        address: ''
      },
      infoWindowOpened: false,
      mapRef: null,
      directionsRenderer: null
    };
  },
  methods: {
    async searchLocation() {
      const geocoder = new google.maps.Geocoder();
      try {
        const results = await new Promise((resolve, reject) => {
          geocoder.geocode({ address: this.searchQuery }, (results, status) => {
            if (status === 'OK') {
              resolve(results);
            } else {
              reject(status);
            }
          });
        });

        if (results && results.length > 0) {
          const location = results[0].geometry.location;
          this.destination = {
            lat: location.lat(),
            lng: location.lng(),
          };
          this.destinationName = results[0].formatted_address;
          this.destinationWindowDetails.address = results[0].formatted_address;

          await this.showRoute();
        } else {
          console.error('No results found');
        }
      } catch (error) {
        console.error('Error during geocoding:', error);
        alert('ジオコーディング中にエラーが発生しました。もう一度試すか、ページをリロードしてください。');
      }
    },
    async showRoute() {
      try {
        if (!this.directionsRenderer) {
          this.directionsRenderer = new google.maps.DirectionsRenderer();
          this.directionsRenderer.setMap(this.$refs.map.$mapObject);
        }

        const directionsService = new google.maps.DirectionsService();
        const routeRequest = {
          origin: this.center,
          destination: this.destination,
          travelMode: 'DRIVING'
        };

        const response = await new Promise((resolve, reject) => {
          directionsService.route(routeRequest, (response, status) => {
            if (status === 'OK') {
              resolve(response);
            } else {
              reject(status);
            }
          });
        });

        const destinationDetails = response.routes[0].legs[0];
        this.destinationWindowDetails.address = destinationDetails.end_address;
        this.directionsRenderer.setDirections(response);
        this.distance = destinationDetails.distance.text;
        this.duration = destinationDetails.duration.text;
      } catch (error) {
        console.error('Directions request failed due to ' + error);
        alert('道順の取得中にエラーが発生しました。もう一度試すか、ページをリロードしてください。');
      }
    },
    async handleDestinationMarkerDragEnd(event) {
      try {
        this.showDistanceDuration = false;
        const newPosition = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        };

        this.destination = newPosition;

        const geocoder = new google.maps.Geocoder();
        const results = await new Promise((resolve, reject) => {
          geocoder.geocode({ location: newPosition }, (results, status) => {
            if (status === 'OK') {
              resolve(results);
            } else {
              reject(status);
            }
          });
        });

        if (results && results.length > 0) {
          this.destinationWindowDetails.address = results[0].formatted_address;
        } else {
          console.error('No results found');
        }

        await this.showRoute();
      } catch (error) {
        console.error('Error during geocoding:', error);
        alert('ジオコーディング中にエラーが発生しました。もう一度試すか、ページをリロードしてください。');
      }
    },
    openInfoWindow() {
      this.infoWindowOpened = true
    },
    closeInfoWindow() {
      this.infoWindowOpened = false
    },
    async showCurrentLocation() {
      try {
        this.showDistanceDuration = false;
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        setTimeout(async () => {
          const geocoder = new google.maps.Geocoder();
          const latlng = new google.maps.LatLng(this.center.lat, this.center.lng);
          const results = await new Promise((resolve, reject) => {
            geocoder.geocode({ 'location': latlng }, (results, status) => {
              if (status === 'OK') {
                resolve(results);
              } else {
                reject(status);
              }
            });
          });

          if (results && results.length > 0) {
            this.currentLocation = results[0].formatted_address;
          } else {
            console.error('No results found');
          }
        }, 1000);
      } catch (error) {
        if (error.message === 'User denied Geolocation') {
          alert('位置情報サービスを有効にしてページをリロードします。');
        } else {
          alert('現在地へのアクセスを許可してページをリロードしてください。');
        }
        console.error('Error getting location:', error.message);
      }
    },
    setMarker(event) {
      this.handleDestinationMarkerDragEnd(event)
    },
    storeCoords() {
      if (this.destination) {
        const storedCoords = Cookies.get('coords')
        if (storedCoords) {
          Cookies.remove('coords')
        }
        Cookies.set('coords', JSON.stringify(this.destination), { expires: 3 })
        alert('無事保存されました！')
      }else {
        alert('地図をクリックするか、検索ボックスに目的地を入力して、目的地を選択します。')
      }
    },
    async setDestinationFromCookie() {
      try {
        const storedCoords = Cookies.get('coords');
        if (storedCoords) {
          const coordsObj = JSON.parse(storedCoords);
          const newPosition = {
            lat: coordsObj.lat,
            lng: coordsObj.lng
          };

          this.destination = newPosition;

          setTimeout(async () => {
            const geocoder = new google.maps.Geocoder();
            const results = await new Promise((resolve, reject) => {
              geocoder.geocode({ location: newPosition }, (results, status) => {
                if (status === 'OK') {
                  resolve(results);
                } else {
                  reject(status);
                }
              });
            });

            if (results && results.length > 0) {
              this.destinationWindowDetails.address = results[0].formatted_address;
            } else {
              console.error('No results found');
            }

            await this.showRoute();
          }, 1000);
        }
      } catch (error) {
        console.error('Error setting destination from cookie:', error);
        alert('保存されている目的地を地図上に表示中にエラーが発生しました。ページを再読み込みしてみてください。');
      }
    },
  },
  mounted() {
    this.showCurrentLocation()
    this.$nextTick(() => {
      this.setDestinationFromCookie()
    })
  },
};
</script>

<style scoped>
.container {
  margin-top: 50px;
}
.map-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-top: 3%;
}
.map-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.windowStyles {
  margin-bottom: 1px;
}
.map-section {
  height: 30rem;
}
@media only screen and (max-width: 768px) {
  .container {
    margin-top: 10px;
  }
  .map-section {
    height: 30rem;
  }
  .windowStyles {
    font-size: 13px;
  }
}
</style>
